import {
  Button,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDialogs } from "@promaton/frontend-common";
import { Grid, Search, Trash } from "@promaton/icons";
import { FC, forwardRef, memo, useMemo } from "react";
import { useForm } from "react-hook-form";
import { TableVirtuoso } from "react-virtuoso";
import { Link } from "wouter";

import { StatusChip } from "../../components/StatusChip";
import { Role, useRole } from "../../hooks/useRole";
import { type APIOutput, trpc } from "../../shared/api/trpc";
import { EmptyState } from "../../shared/ui/EmptyState";
import { formatDate, formatTime } from "../../shared/utils/dateFormatter";
import { createRouteLink, routes } from "../routes";

type Submission = APIOutput["submission"]["listAllReviewsForUser"][number];
type State = Submission["state"];

const Scroller = forwardRef<HTMLDivElement>((props, ref) => (
  <div {...props} ref={ref}></div>
));

const CustomTable: FC = (props) => (
  <Table size="small" {...props} style={{ borderCollapse: "separate" }} />
);

const CustomTableHead: FC = (props) => (
  <TableHead {...props} sx={{ top: "74px !important" }} />
);

const CustomTableRow: FC = (props) => (
  <TableRow
    {...props}
    sx={{
      ["& td"]: {
        borderBottomColor: (t) => t.palette.divider,
      },
      ["&:not(:hover) a, &:not(:hover) button"]: {
        opacity: 0,
      },
    }}
  />
);

const CustomTableBody = forwardRef<HTMLTableSectionElement>((props, ref) => (
  <TableBody {...props} ref={ref} />
));

const CustomHeaderContent = (id: string | undefined) => (
  <TableRow
    sx={{
      background: (t) => t.palette.background.default,

      ["& th"]: {
        borderBottomColor: (t) => t.palette.divider,
      },
    }}
  >
    <TableCell sx={{ paddingLeft: 0 }}>Slug</TableCell>
    {!id && <TableCell>Project</TableCell>}
    <TableCell>Annotator</TableCell>
    <TableCell>Duration</TableCell>
    <TableCell>Submitted on</TableCell>
    <TableCell>Status</TableCell>
    <TableCell align="right"></TableCell>
  </TableRow>
);

const CustomItemContent = (submission: Submission, id: string | undefined) =>
  submission ? (
    <SubmissionTableRow
      submission={submission}
      key={submission.id}
      showProject={!id}
    />
  ) : null;

export const ReviewTable: FC<{
  id?: string;
  states?: State[];
}> = ({ id, states }) => {
  const { register, watch } = useForm<{
    search: string;
  }>();

  const search = watch("search");
  const submissionQuery =
    trpc.submission.listAllReviewsForUser.useInfiniteQuery(
      {
        project: id,
        search: search || undefined,
        states,
      },
      {
        getNextPageParam: (lastPage) => lastPage.at(-1)?.id,
        cacheTime: 1,
      }
    );

  const submissions = useMemo(() => {
    return submissionQuery.data?.pages.flat();
  }, [submissionQuery.data]);

  const getProjectString = () => (id ? " in project" : "");

  return (
    <>
      <InputBase
        startAdornment={<Search sx={{ marginRight: 1 }} />}
        type="search"
        placeholder="Search by slug, annotator or reviewer..."
        autoComplete="off"
        {...register("search", {
          minLength: 1,
        })}
        fullWidth
        sx={{
          flex: 1,
          padding: 1,
          paddingLeft: 2,
          background: (t) => t.palette.action.hover,
          borderRadius: (t) => t.spacing(1),
        }}
      />

      <TableVirtuoso
        useWindowScroll
        endReached={() => {
          submissionQuery.fetchNextPage();
        }}
        components={{
          Scroller,
          Table: CustomTable,
          TableHead: CustomTableHead,
          TableRow: CustomTableRow,
          TableBody: CustomTableBody,
        }}
        style={{
          flex: "0 0 auto",
          height: 100,
          width: "100%",
        }}
        totalCount={submissions?.length}
        data={submissions}
        fixedHeaderContent={() => CustomHeaderContent(id)}
        itemContent={(_, submission) => CustomItemContent(submission, id)}
      />
      {!submissionQuery.isLoading && submissions?.length === 0 && (
        <EmptyState>
          <Grid fontSize="large"></Grid>
          <Typography>
            No submissions
            {search ? ` for query ${search}` : getProjectString()}
          </Typography>
        </EmptyState>
      )}
    </>
  );
};

const SubmissionTableRow: FC<{
  submission: Submission;
  showProject?: boolean;
}> = memo(({ submission, showProject }) => {
  const deleteSubmission = trpc.submission.delete.useMutation();
  const utils = trpc.useContext();
  const role = useRole();

  return (
    <>
      <TableCell sx={{ paddingLeft: 0, width: 200 }}>
        {submission.assignment.sourceSlug}
      </TableCell>
      {showProject && (
        <TableCell
          sx={{
            width: 100,
            wordBreak: "break-word",
          }}
        >
          {submission.assignment.project.name}
        </TableCell>
      )}
      <TableCell>{submission.annotator.name}</TableCell>
      <TableCell>
        {submission.annotationDurationSeconds
          ? formatTime(submission.annotationDurationSeconds)
          : "-"}
      </TableCell>
      <TableCell>
        {submission.submittedAt ? formatDate(submission.submittedAt) : "-"}
      </TableCell>
      <TableCell>
        <StatusChip submission={submission} chipProps={{ variant: "filled" }} />
      </TableCell>
      <TableCell sx={{ paddingRight: 0, whiteSpace: "nowrap" }} align="right">
        <Link
          href={createRouteLink(routes.assignment, {
            slug: submission.assignment.sourceSlug,
            id: submission.id,
          })}
        >
          <Button>View</Button>
        </Link>
        {role === Role.ADMIN && (
          <IconButton
            disabled={deleteSubmission.isLoading}
            onClick={() => {
              useDialogs.getState().setConfirmation({
                title: `Are you sure you want to delete the annotation of ${submission.assignment.sourceSlug} by ${submission.annotator.name}?`,
                description:
                  "This is not reversable and will cause the loss of annotation data",
                onConfirm: async () => {
                  await deleteSubmission.mutateAsync({ id: submission.id });
                  utils.submission.invalidate();
                  utils.project.invalidate();
                },
              });
            }}
          >
            <Trash />
          </IconButton>
        )}
      </TableCell>
    </>
  );
});
