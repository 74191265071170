import {
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useMemo } from "react";
import { Helmet } from "react-helmet";

import { LoginButton } from "./LoginButton";

export const LoginPage: FC<{}> = () => {
  const hasError = useMemo(() => {
    const error = new URLSearchParams(location.search).get("error");
    return !!error;
  }, []);

  return (
    <Container
      sx={{
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Helmet>
        <title>Login · Promaton Annotator</title>
      </Helmet>
      <Card
        variant="outlined"
        sx={{
          boxShadow: `0 0.5rem 2rem -0.6rem rgba(0,0,0,0.15)`,
          width: "100%",
          maxWidth: 275,
          padding: 4,
          borderRadius: 3,
          userSelect: "none",
        }}
      >
        <CardContent>
          <Stack gap={4} alignItems="center">
            <Stack gap={1} alignItems="center">
              <img src="/promaton-logo.png" style={{ height: 80 }} />
              <Typography variant="h5">
                <span style={{ fontWeight: "bold" }}>Promaton</span> Annotator
              </Typography>
              <Typography variant="body2">
                Dental scan annotation tool
              </Typography>
            </Stack>
            <Divider sx={{ width: "100%" }} />
            <LoginButton
              fullWidth
              label="Login with Google"
              size="large"
              variant="contained"
            />
            {hasError && (
              <Typography color="error" variant="body2">
                Login failed. Is your Google account authorized?
              </Typography>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};
