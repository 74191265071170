import "survey-core/defaultV2.min.css";

import { styled } from "@mui/material";
import { StylesManager } from "survey-core";

StylesManager.applyTheme("defaultV2");

export const SurveyContainer = styled("div")`
  ${({ theme }) => `
  overflow-y: auto;
  flex: 1;

  > * {
    --primary:${
      theme.palette.mode === "light"
        ? theme.palette.primary.light
        : theme.palette.primary.dark
    }};
    --background-dim:${theme.palette.background.paper};
    --background:${theme.palette.background.paper};
    --background-dim-light:${theme.palette.divider};;
    --primary-foreground:${theme.palette.primary.contrastText};
    --foreground:${theme.palette.text.primary};
    --foreground-disabled:${theme.palette.text.secondary};
    --sjs-font-editorfont-color: ${theme.palette.text.primary};
    --sjs-general-forecolor:${theme.palette.text.primary};

    .sd-container-modern {
      --sd-base-vertical-padding: 8px !important;
      --sd-base-padding: 4px !important;
    }

    .sd-page {
      padding-top: 0 !important;
    }

    .sd-question {
      box-shadow: none !important;
      background: none;
      padding-top: 0 !important;
      padding: ${theme.spacing(1)} !important;

      .sd-question__header {
        position: sticky;
        top: 0;
        z-index: ${theme.zIndex.fab};
        background: var(--background);
        padding: ${theme.spacing(2)} 0;
      }
    }

    .sd-imagepicker__text {
      white-space: normal;
      max-width: 150px;
    }

    .sd-text {
      border: 1px solid ${theme.palette.divider};
    }

    .sd-radio--disabled *  {
      *:after {
        background-color: var(--primary) !important;
      }

    }

    .sd-comment, .sd-input {
      background: transparent !important;
    }

    .sd-imagepicker__item--checked {
    .sd-imagepicker__check-decorator {
      width: 1.5rem;
      height: 1.5rem;
      top: 75%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid var(--primary);
      padding: 0;
      place-items: center;

        display: grid !important;

        svg {
          height: 1rem;
          width: 1rem;
        }
      }
    }

    .sd-checkbox--checked.sd-checkbox--disabled .sd-checkbox__svg use {
      fill: var(--primary);
    }

    .sd-element__title--disabled {
      opacity: 1.0 !important;
    }
  }
`}
`;
