import { zodResolver } from "@hookform/resolvers/zod";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CaretLeft } from "@promaton/icons";
import { FC, lazy, Suspense, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useLocation } from "wouter";
import * as z from "zod";

import { AssignmentConfigurator } from "../../../components/AssignmentConfigurator/AssignmentConfigurator";
import { trpc } from "../../../shared/api/trpc";
import { AssignmentType } from "../../../shared/static/AssignmentType";
import { createRouteLink, routes } from "../../routes";
import {
  DataSources,
  projectFormSchema,
} from "../create-project/CreateProjectPage";

const FormEditor = lazy(() => import("../../../components/FormEditor"));
const updateProjectSchema = projectFormSchema.omit({ assignmentType: true });

type ProjectFormSchema = z.infer<typeof updateProjectSchema>;

export const EditProjectPage: FC<{ id: string }> = ({ id }) => {
  const [_, setLocation] = useLocation();
  const project = trpc.project.get.useQuery({ id });
  const updateProject = trpc.project.update.useMutation();
  const utils = trpc.useContext();
  const [config, setConfig] = useState<object>();
  const groups = trpc.group.list.useQuery();
  const assignmentType = useMemo(() => {
    return project.data?.assignments[0]?.type;
  }, [project.data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    setValue,
    watch,
  } = useForm<ProjectFormSchema>({
    resolver: zodResolver(updateProjectSchema),
    defaultValues: {
      name: "Project",
      submissionsPerAssignment: 1,
      groups: [],
      skipReview: false,
      loadAnnotations: false,
      automatedLakehouseSyncEnabled: false,
      numberOfReviewsPerSubmission: 0,
      userGroupListPerReview: [[]],
    },
  });

  useEffect(() => {
    if (project.data) {
      setValue("name", project.data.name);
      setValue(
        "submissionsPerAssignment",
        project.data.submissionsPerAssignment
      );
      setValue(
        "groups",
        project.data.groups.map((g) => g.name)
      );
      setValue("skipReview", project.data.skipReview);
      setValue("loadAnnotations", project.data.loadAnnotations);
      setValue(
        "automatedLakehouseSyncEnabled",
        project.data.automatedLakehouseSyncEnabled
      );
      project.data.assignmentConfig &&
        setValue(
          "assignmentConfig",
          JSON.stringify(project.data.assignmentConfig, undefined, 2)
        );
      setConfig(project.data.assignmentConfig as object);
      setValue(
        "numberOfReviewsPerSubmission",
        project.data.numberOfReviewsPerSubmission
      );

      setValue(
        "userGroupListPerReview",
        project.data.userGroupListPerReview.map((review) =>
          review.groups.map((group: { name: string }) => group.name)
        )
      );
    }
  }, [project.data]);

  const numberOfReviewsPerSubmission = watch("numberOfReviewsPerSubmission", 0);

  useEffect(() => {
    if (!numberOfReviewsPerSubmission) {
      return;
    }

    const currentReviews = getValues("userGroupListPerReview");
    if (!currentReviews) {
      return;
    }

    const newReviews = Array.from(
      { length: numberOfReviewsPerSubmission },
      (_, i) => currentReviews[i] || []
    );
    setValue("userGroupListPerReview", newReviews);
  }, [numberOfReviewsPerSubmission]);

  return (
    <Container sx={{ marginY: 2 }} maxWidth={"xl"}>
      {updateProject.isLoading && (
        <LinearProgress
          sx={{ position: "absolute", left: 0, right: 0, zIndex: 10 }}
        />
      )}

      <Stack flexDirection="row" alignItems="center" gap={2}>
        <Link href={createRouteLink(routes.projectDetail, { id })}>
          <IconButton>
            <CaretLeft />
          </IconButton>
        </Link>
        <Typography variant="h4" fontWeight={"bold"} mt={3} mb={3} flex={1}>
          {project.data?.name}
        </Typography>
      </Stack>

      <form
        onSubmit={handleSubmit(async (d) => {
          if (project.isLoading) return;
          await updateProject.mutateAsync({
            id,
            name: d.name,
            submissionsPerAssignment: d.submissionsPerAssignment,
            groups: d.groups,
            skipReview: d.skipReview,
            loadAnnotations: d.loadAnnotations,
            automatedLakehouseSyncEnabled: d.automatedLakehouseSyncEnabled,
            assignmentConfig: d.assignmentConfig
              ? JSON.parse(d.assignmentConfig)
              : undefined,
            numberOfReviewsPerSubmission: d.numberOfReviewsPerSubmission,
            userGroupListPerReview: d.userGroupListPerReview,
          });

          utils.project.invalidate();
          utils.assignment.invalidate();
          utils.submission.invalidate();

          setLocation(createRouteLink(routes.projectDetail, { id }));
        })}
      >
        <Stack alignItems={"flex-start"} gap={3} pb={3}>
          <Typography variant="h6">Project settings</Typography>
          <TextField
            error={!!errors.name}
            helperText={errors.name?.message as string}
            fullWidth
            autoComplete="off"
            label="Project name"
            {...register("name")}
          />

          <TextField
            error={!!errors.submissionsPerAssignment}
            helperText={errors.submissionsPerAssignment?.message as string}
            fullWidth
            type="number"
            label="Target number of submissions per assignment"
            {...register("submissionsPerAssignment", { valueAsNumber: true })}
          />
          <Stack>
            <Controller
              control={control}
              name="skipReview"
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Skip reviews (assignments are automatically approved)"
                />
              )}
            />
            {project.data?.dataSource === DataSources.LAKEHOUSE_V2 && (
              <>
                <Controller
                  control={control}
                  name="loadAnnotations"
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label="Load existing annotations from lakehouse"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="automatedLakehouseSyncEnabled"
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label="Enable automated sync of new annotations to lakehouse"
                    />
                  )}
                />
              </>
            )}
          </Stack>
          <Typography variant="h6">Review management</Typography>
          <Typography>
            Number of reviews per submission and the groups that will review the
            submissions. Number of reviews can not be changed after project
            creation.
          </Typography>
          <TextField
            disabled
            error={!!errors.numberOfReviewsPerSubmission}
            helperText={errors.numberOfReviewsPerSubmission?.message as string}
            fullWidth
            type="number"
            label="Target number of reviews per submission"
            {...register("numberOfReviewsPerSubmission", {
              valueAsNumber: true,
            })}
          />
          <Controller
            name="userGroupListPerReview"
            control={control}
            render={({ field }) => (
              <>
                <Typography>
                  If no group is selected, any reviewer can review.
                </Typography>
                {groups.isLoading || project.isLoading ? (
                  <CircularProgress />
                ) : (
                  field.value?.map((f, index) => (
                    <Autocomplete
                      disabled={watch("skipReview")}
                      key={index}
                      value={f}
                      multiple
                      fullWidth
                      {...register(`userGroupListPerReview.${index}`)}
                      onChange={(_, v) => {
                        const newValue = [...(field.value || [])];
                        newValue[index] = v;
                        field.onChange(newValue);
                      }}
                      options={groups.data?.map((option) => option.name) || []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Review groups ${index + 1}`}
                          placeholder="Groups"
                        />
                      )}
                    />
                  ))
                )}
              </>
            )}
          />
          <Typography variant="h6">Access management</Typography>
          <Typography>
            Add groups that can access the project below. If empty, any group
            can access.
          </Typography>
          <Controller
            name="groups"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                fullWidth
                onChange={(_, v) => {
                  setValue("groups", v);
                }}
                options={groups.data?.map((option) => option.name) || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Limit access to groups"
                    placeholder="Groups"
                  />
                )}
              />
            )}
          />

          <Suspense>
            {assignmentType === AssignmentType.SURVEY && (
              <FormEditor
                data={config}
                errorText={errors.assignmentConfig?.message}
                onRefresh={() => {
                  const form = getValues("assignmentConfig");
                  setConfig(form ? JSON.parse(form) : {});
                }}
                {...register("assignmentConfig")}
              />
            )}
          </Suspense>

          {assignmentType === AssignmentType.ANNOTATION && (
            <AssignmentConfigurator
              value={config}
              onChange={(v) => {
                setValue("assignmentConfig", JSON.stringify(v));
              }}
            />
          )}

          <Button
            disabled={updateProject.isLoading}
            size="large"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </Stack>
      </form>
    </Container>
  );
};
