import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  IconButton,
  Stack,
  TableCell,
  Typography,
} from "@mui/material";
import { useDialogs } from "@promaton/frontend-common";
import { Trash } from "@promaton/icons";
import { FC, memo, useCallback } from "react";
import { generateUUID } from "three/src/math/MathUtils";
import { Link, useLocation } from "wouter";

import { StatusChip } from "../../components/StatusChip";
import { Role, useRole } from "../../hooks/useRole";
import { APIOutput, trpc } from "../../shared/api/trpc";
import {
  AssignmentIcon,
  AssignmentTypeName,
} from "../../shared/static/AssignmentType";
import { formatDate } from "../../shared/utils/dateFormatter";
import { createRouteLink, routes } from "../routes";

type Submission = APIOutput["submission"]["listOwn"][number];
type Assignment = APIOutput["assignment"]["get"];

export const AssignmentCard: FC<{
  submission: Submission;
  reviewMode?: boolean;
}> = memo(({ submission, reviewMode }) => {
  const utils = trpc.useContext();
  const deleteSubmission = trpc.submission.delete.useMutation();
  const link = createRouteLink(routes.assignment, {
    id: submission.id,
    slug: submission.assignment.sourceSlug,
  });

  return (
    <Card
      sx={{
        cursor: "pointer",
        borderRadius: 3,
        boxShadow: "0 0.5rem 0.75rem -0.5rem rgba(0,0,0,0.15)",
        ["& .hidden"]: {
          opacity: 0,
        },
        ["&:hover"]: {
          boxShadow: "0 1.5rem 1rem -0.75rem rgba(0,0,0,0.1)",
          ["& .hidden"]: {
            opacity: 1,
          },
        },
      }}
    >
      <Link href={link}>
        <CardActionArea>
          <CardContent>
            <Stack gap={1}>
              <Stack
                sx={{ marginBottom: 1 }}
                flexDirection="row"
                gap={1}
                alignItems="center"
              >
                <Avatar
                  sx={{
                    background: (t) => t.palette.divider,
                    color: (t) => t.palette.text.primary,
                  }}
                >
                  {AssignmentIcon[submission.assignment.type]}
                </Avatar>
                <Typography
                  variant="body2"
                  sx={{
                    flex: 1,
                    wordBreak: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineClamp: 2,
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {submission.assignment.project.name}
                </Typography>
                {!reviewMode && (
                  <IconButton
                    className="hidden"
                    disabled={deleteSubmission.isLoading}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      useDialogs.getState().setConfirmation({
                        title: `Are you sure you want to delete your annotation of ${submission.assignment.sourceSlug}?`,
                        description:
                          "Your progress will be permanently deleted!",
                        onConfirm: async () => {
                          await deleteSubmission.mutateAsync({
                            id: submission.id,
                          });
                          utils.submission.invalidate();
                          utils.project.invalidate();
                        },
                      });
                    }}
                  >
                    <Trash />
                  </IconButton>
                )}
              </Stack>
              <Typography variant="h6">
                {submission.assignment.sourceSlug}
              </Typography>
              <Typography variant="body1">
                {reviewMode ? (
                  `by ${submission.annotator.name}`
                ) : (
                  <StatusChip
                    editable
                    submission={submission}
                    chipProps={{ variant: "filled" }}
                  />
                )}
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.7 }}>
                {AssignmentTypeName[submission.assignment.type]} · Updated at{" "}
                {formatDate(submission.updatedAt)}
              </Typography>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
});

export const AssignmentTableRow: FC<{ assignment: Assignment }> = memo(
  ({ assignment }) => {
    const [_, setLocation] = useLocation();
    const createSubmission = trpc.submission.create.useMutation();
    const utils = trpc.useContext();
    const role = useRole();

    const startAssignment = useCallback(async () => {
      try {
        if (role === Role.REVIEWER) return;
        const submission = await createSubmission.mutateAsync({
          id: assignment.id,
        });

        const link = createRouteLink(routes.assignment, {
          id: submission.id,
          slug: assignment.sourceSlug,
        });

        utils.assignment.invalidate();
        utils.submission.invalidate();
        useDialogs.getState().createNotification({
          id: generateUUID(),
          text: "Started assignment",
          color: "success",
        });
        setLocation(link);
      } catch (error) {
        console.error(error);
        useDialogs.getState().createNotification({
          id: generateUUID(),
          text: "Failed to start assignment",
          color: "error",
        });
      }
    }, []);

    return (
      <>
        <TableCell sx={{ paddingLeft: 0, width: 300 }}>
          {assignment.sourceSlug}
        </TableCell>
        <TableCell>
          <Chip
            icon={AssignmentIcon[assignment.type]}
            variant="outlined"
            label={AssignmentTypeName[assignment.type]}
          />
        </TableCell>
        <TableCell
          sx={{
            wordBreak: "break-word",
          }}
        >
          {assignment.project.name}
        </TableCell>
        <TableCell>
          {`${new Date(assignment.createdAt).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}`}
        </TableCell>
        <TableCell>
          <Chip
            variant="outlined"
            color={assignment.isAvailable ? "info" : "secondary"}
            label={assignment.isAvailable ? "Available" : "Unavailable"}
          />
        </TableCell>
        <TableCell sx={{ paddingRight: 0 }} align="right">
          {role !== Role.REVIEWER && (
            <Button
              disabled={createSubmission.isLoading}
              onClick={() => startAssignment()}
            >
              Annotate
            </Button>
          )}
        </TableCell>
      </>
    );
  }
);
