import { Chip, ChipProps, Menu, Stack } from "@mui/material";
import { CaretDown } from "@promaton/icons";
import { sentenceCase } from "change-case";
import { FC, useMemo, useState } from "react";

import { Role, useRole } from "../hooks/useRole";
import { useSubmissionStateUpdate } from "../hooks/useSubmissionStateUpdate";
import { type APIOutput, trpc } from "../shared/api/trpc";
import { StatusColor } from "../shared/static/StatusColor";
import { SubmissionStateType } from "../shared/static/SubmissionStateType";
import { getIsFinalReview } from "../shared/utils/utils";
import { useAppState } from "../stores/useAppState";

type Submission = APIOutput["submission"]["get"];
type SubmissionState = Submission["state"];

export const StatusChip: FC<{
  submission: Pick<
    Submission,
    "id" | "state" | "assignment" | "reviews" | "annotatorEmail"
  >;
  chipProps?: ChipProps;
  editable?: boolean;
}> = ({ submission, chipProps, editable }) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const role = useRole();
  const userEmail = useAppState((s) => s.session?.email);
  const { handleSubmissionStateUpdate } = useSubmissionStateUpdate();
  const draftReviewQuery = trpc.review.fetchDraft.useQuery(
    {
      id: submission.id,
    },
    { enabled: role !== Role.ANNOTATOR }
  );

  const allowedState = useMemo(() => {
    if (role === Role.ANNOTATOR) {
      return [SubmissionStateType.IN_PROGRESS, SubmissionStateType.ANNOTATED];
    }

    const hasDraftReview = !!draftReviewQuery.data?.isDraft;
    const isFinalReview = getIsFinalReview(
      submission,
      submission.assignment.project.numberOfReviewsPerSubmission
    );
    const reviewOutcomes: SubmissionStateType[] = [];

    if (hasDraftReview) {
      reviewOutcomes.push(
        isFinalReview
          ? SubmissionStateType.APPROVED
          : SubmissionStateType.REVIEWED
      );
    }

    // Allow rejection if reviewing or if already approved
    if (hasDraftReview || submission.state === SubmissionStateType.APPROVED) {
      reviewOutcomes.push(SubmissionStateType.REJECTED);
    }

    // If admin is annotator and submission is not in review.
    if (
      userEmail &&
      role === Role.ADMIN &&
      submission.annotatorEmail === userEmail &&
      (
        [
          SubmissionStateType.IN_PROGRESS,
          SubmissionStateType.REJECTED,
          SubmissionStateType.ANNOTATED,
        ] as SubmissionState[]
      ).includes(submission.state)
    ) {
      reviewOutcomes.unshift(
        SubmissionStateType.IN_PROGRESS,
        SubmissionStateType.ANNOTATED
      );
    }

    return reviewOutcomes;
  }, [role, draftReviewQuery.data, submission, userEmail]);

  return (
    <>
      <Chip
        onClick={
          editable
            ? (e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchor(e.currentTarget);
              }
            : undefined
        }
        variant="outlined"
        {...chipProps}
        onDelete={
          editable
            ? () => {
                return;
              }
            : undefined
        }
        sx={{ fontWeight: "bold" }}
        deleteIcon={<CaretDown />}
        color={StatusColor[submission.state]}
        label={sentenceCase(submission.state)}
      />
      <Menu
        onClick={(e) => e.stopPropagation()}
        id="menu-appbar"
        anchorEl={anchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          variant: "outlined",
          elevation: undefined,
          sx: {
            padding: 0,
            borderRadius: 4,
          },
        }}
        open={Boolean(anchor)}
        onClose={() => {
          setAnchor(null);
        }}
      >
        <Stack px={1.5} py={0.5} gap={1} sx={{ width: 150 }}>
          {allowedState.map((state) => {
            if (!state) return null;
            return (
              <Chip
                variant={state === submission.state ? "filled" : "outlined"}
                onClick={() =>
                  handleSubmissionStateUpdate(
                    state,
                    submission,
                    draftReviewQuery.data?.id
                  )
                }
                sx={{ fontWeight: "bold", height: 40, borderRadius: 2 }}
                key={state}
                color={StatusColor[state]}
                label={sentenceCase(state)}
              />
            );
          })}
        </Stack>
      </Menu>
    </>
  );
};
