import type { APIOutput } from "../api/trpc";

type Submission = APIOutput["submission"]["get"];
type SubmissionState = Submission["state"];

export const StatusColor = {
  IN_PROGRESS: "warning",
  ANNOTATED: "secondary",
  REVIEWED: "info",
  APPROVED: "primary",
  REJECTED: "error",
} as const satisfies { [key in SubmissionState]: string };
