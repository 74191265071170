import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Profile, Trash } from "@promaton/icons";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { Link } from "wouter";

import { trpc } from "../../../shared/api/trpc";
import { createRouteLink, routes } from "../../routes";

export const AdminGroupsTable: FC<{}> = () => {
  const { register, handleSubmit, reset } = useForm<{
    name: string;
  }>();
  const groups = trpc.group.list.useQuery();
  const createGroup = trpc.group.create.useMutation();
  const utils = trpc.useContext();
  const deleteGroup = trpc.group.delete.useMutation();

  return (
    <>
      <form
        onSubmit={handleSubmit(async (data) => {
          await createGroup.mutateAsync({
            name: data.name,
          });
          utils.group.list.invalidate();
          reset();
        })}
      >
        <Stack flexDirection={"row"} gap={2} mb={3}>
          <InputBase
            placeholder="Add group"
            autoComplete="off"
            {...register("name", {
              minLength: 1,
            })}
            fullWidth
            sx={{
              flex: 1,
              padding: 1,
              paddingLeft: 2,
              background: (t) => t.palette.action.hover,
              borderRadius: (t) => t.spacing(1),
            }}
          />
          <Button variant="contained" type="submit">
            Add
          </Button>
        </Stack>
      </form>

      <Grid2 container spacing={2} pb={2}>
        {groups.data?.map((group) => (
          <Grid2 xs={12} md={6} lg={4} key={group.id}>
            <Card
              sx={{
                cursor: "pointer",
                borderRadius: 3,
                boxShadow: "0 0.5rem 0.75rem -0.5rem rgba(0,0,0,0.15)",
                ["& .hidden"]: {
                  opacity: 0,
                },
                ["&:hover"]: {
                  boxShadow: "0 1.5rem 1rem -0.75rem rgba(0,0,0,0.1)",
                  ["& .hidden"]: {
                    opacity: 1,
                  },
                },
              }}
            >
              <Link
                href={createRouteLink(routes.group, {
                  id: group.id,
                })}
              >
                <CardActionArea>
                  <CardContent>
                    <Stack flexDirection="row" gap={2} alignItems="center">
                      <Avatar>
                        <Profile />
                      </Avatar>
                      <Stack flex={1}>
                        <Typography variant="h6">{group.name}</Typography>
                        <Typography variant="body2">
                          {group.members.length} members
                        </Typography>
                      </Stack>
                      <IconButton
                        onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          await deleteGroup.mutateAsync({
                            name: group.name,
                          });
                          utils.group.list.invalidate();
                        }}
                      >
                        <Trash />
                      </IconButton>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </>
  );
};
