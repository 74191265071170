import {
  Button,
  Container,
  InputBase,
  LinearProgress,
  Stack,
  Typography,
  Unstable_Grid2 as Grid2,
} from "@mui/material";
import { Grid, Search } from "@promaton/icons";
import { useForm } from "react-hook-form";
import { Link } from "wouter";

import { trpc } from "../../../shared/api/trpc";
import { EmptyState } from "../../../shared/ui/EmptyState";
import { createRouteLink, routes } from "../../routes";
import { ProjectCard } from "./ProjectCard";

export const ProjectsPage = () => {
  const { register, watch } = useForm<{
    search: string;
  }>();
  const search = watch("search");
  const projects = trpc.project.getAll.useQuery({
    archived: false,
    search: search || undefined,
  });
  const archived = trpc.project.getAll.useQuery({
    archived: true,
    search: search || undefined,
  });

  return (
    <>
      {projects.isLoading && (
        <LinearProgress
          sx={{ position: "absolute", left: 0, right: 0, zIndex: 10 }}
        />
      )}
      <Container sx={{ marginY: 2 }} maxWidth={"xl"}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent={"space-between"}
          gap={2}
        >
          <Typography variant="h4" fontWeight={"bold"} mt={3} mb={3}>
            Projects
          </Typography>
          <InputBase
            startAdornment={<Search sx={{ marginRight: 1 }} />}
            type="search"
            placeholder="Search by name or group"
            autoComplete="off"
            {...register("search", {
              minLength: 1,
            })}
            fullWidth
            sx={{
              flex: 1,
              maxWidth: 500,
              padding: 1,
              paddingLeft: 2,
              background: (t) => t.palette.action.hover,
              borderRadius: (t) => t.spacing(1),
            }}
          />
          <Link href={createRouteLink(routes.newProject, {})}>
            <Button variant="contained">Create project</Button>
          </Link>
        </Stack>

        {projects.error && <EmptyState>Failed to load projects</EmptyState>}
        {projects.data?.length === 0 && (
          <EmptyState>
            <Grid fontSize="large"></Grid>
            <Typography>
              {search
                ? `No projects matching query "${search}"`
                : "No projects"}
            </Typography>
          </EmptyState>
        )}
        <Grid2 container spacing={2} pb={2}>
          {projects.data?.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </Grid2>

        {!!archived.data?.length && (
          <Typography variant="h6" mt={2} mb={2}>
            Archived
          </Typography>
        )}
        <Grid2 container spacing={2} pb={2}>
          {archived.data?.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </Grid2>
      </Container>
    </>
  );
};
