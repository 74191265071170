import {
  Avatar,
  Container,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "wouter";

import { Profile } from "../../components/Profile";
import { useQueryAndSessionState } from "../../hooks/useQueryAndSessionState";
import { useRole } from "../../hooks/useRole";
import { trpc } from "../../shared/api/trpc";
import { routes } from "../routes";
import { ReviewTable } from "./ReviewTable";

export const Reviewpage = () => {
  const [project, setProject] = useQueryAndSessionState(
    "annotation-project",
    ""
  );
  const role = useRole();
  const projects = trpc.project.getAll.useQuery({ archived: false });
  const isAnnotator = role === "annotator";

  return (
    <Stack sx={{ height: "100%" }}>
      <Helmet>
        <title>Promaton Annotator</title>
      </Helmet>
      <Paper
        variant="outlined"
        sx={{
          paddingY: 0.5,
          border: "none",
          position: "sticky",
          top: 0,
          zIndex: (t) => t.zIndex.fab,
          background: (t) => t.palette.background.default,
        }}
      >
        <Container maxWidth={"xl"}>
          <Stack
            flexDirection={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ marginY: 2, gap: 2 }}
          >
            <Link href={routes.root}>
              <Avatar
                src="/promaton-logo.png"
                sx={{
                  filter: (t) =>
                    t.palette.mode === "light"
                      ? "brightness(0.1)"
                      : "brightness(10)",
                  transition: (t) =>
                    t.transitions.create("all", { duration: "0.5s" }),
                  ["&:hover"]: {
                    transform: "rotate(360deg)",
                  },
                }}
                imgProps={{
                  draggable: false,
                }}
              />
            </Link>
            <Typography sx={{ flex: 0 }} variant="h6">
              Review
            </Typography>

            <Stack sx={{ flex: 1, marginLeft: 2 }}>
              <Select
                placeholder="Project"
                variant="outlined"
                displayEmpty
                renderValue={project !== "" ? undefined : () => "All projects"}
                sx={{
                  paddingLeft: 1,
                  border: "none",
                  width: 250,
                  background: (t) => t.palette.action.hover,
                  borderRadius: (t) => t.spacing(1),
                }}
                value={project}
                onChange={(e) => setProject(e.target.value)}
              >
                <MenuItem value={""}>All projects</MenuItem>
                {projects.data?.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>

            <Profile />
          </Stack>
        </Container>
      </Paper>
      <Container sx={{ marginY: 2 }} maxWidth={"xl"}>
        <Stack gap={2}>
          <ReviewTable
            states={["ANNOTATED", "REVIEWED", "APPROVED", "REJECTED"]}
            id={project || undefined}
          />
        </Stack>
      </Container>
      {isAnnotator && <Redirect href="/" replace />}
    </Stack>
  );
};
