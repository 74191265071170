import {
  Chip,
  Container,
  Divider,
  IconButton,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import { CaretLeft, Search } from "@promaton/icons";
import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Link } from "wouter";

import { trpc } from "../../../shared/api/trpc";
import { createRouteLink, routes } from "../../routes";

export const EditGroupPage: FC<{ id: string }> = ({ id }) => {
  const group = trpc.group.get.useQuery({ id });
  const utils = trpc.useContext();

  const removeMembers = trpc.group.removeMembers.useMutation({
    onSuccess: () => {
      utils.group.get.invalidate({ id });
      utils.group.list.invalidate();
    },
  });
  const addMembers = trpc.group.addMembers.useMutation({
    onSuccess: () => {
      utils.group.get.invalidate({ id });
      utils.group.list.invalidate();
    },
  });

  const { register, watch } = useForm<{
    search: string;
  }>();
  const search = watch("search");

  const members = group.data?.members || [];

  const users = trpc.user.list.useQuery({
    limit: 60,
    search: search || undefined,
  });

  const invitees = trpc.user.listInvites.useQuery({
    search: search || undefined,
  });

  const allUsers = useMemo(() => {
    const _users = (users.data?.result || [])
      .map((u) => u.email)
      .filter(Boolean) as string[];
    const _invitees = (invitees.data || []).map((u) => u.email);
    return Array.from(new Set([..._users, ..._invitees])).sort((a, b) =>
      a.localeCompare(b)
    );
  }, [users.data?.result, invitees.data]);

  return (
    <>
      <Container sx={{ marginY: 2 }} maxWidth={"xl"}>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Link href={createRouteLink(routes.users, {})}>
            <IconButton>
              <CaretLeft />
            </IconButton>
          </Link>
          <Typography variant="h4" fontWeight={"bold"} mt={3} mb={3} flex={1}>
            {group.isLoading ? "..." : group.data?.name}
          </Typography>
        </Stack>

        <Typography variant="h6" mt={2} mb={1}>
          Members ({members.length})
        </Typography>
        <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} mt={2}>
          {members.map((user) => {
            return (
              <Chip
                label={user.email}
                key={user.email}
                onDelete={() =>
                  user &&
                  removeMembers.mutateAsync({
                    groupId: id,
                    users: [user.email],
                  })
                }
              />
            );
          })}
        </Stack>

        <Divider sx={{ my: 4 }} />

        <Typography variant="h6" mt={2} mb={2}>
          Add members
        </Typography>
        <InputBase
          startAdornment={<Search sx={{ marginRight: 1 }} />}
          type="search"
          placeholder="Search by email"
          autoComplete="off"
          {...register("search", {
            minLength: 1,
          })}
          fullWidth
          sx={{
            flex: 1,
            padding: 1,
            paddingLeft: 2,
            background: (t) => t.palette.action.hover,
            borderRadius: (t) => t.spacing(1),
          }}
        />
        <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} mt={2}>
          {allUsers.map((user) => {
            const isMember = !!members.some((member) => member.email === user);

            return (
              <Chip
                label={user}
                key={user}
                variant={isMember ? "filled" : "outlined"}
                disabled={addMembers.isLoading}
                onDelete={
                  isMember
                    ? () =>
                        user &&
                        removeMembers.mutateAsync({
                          groupId: id,
                          users: [user],
                        })
                    : undefined
                }
                onClick={
                  isMember
                    ? undefined
                    : () => {
                        addMembers.mutateAsync({
                          groupId: id,
                          users: [user],
                        });
                      }
                }
              />
            );
          })}
        </Stack>
      </Container>
    </>
  );
};
