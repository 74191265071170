import {
  Button,
  Container,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { CaretLeft } from "@promaton/icons";
import { FC, lazy, Suspense, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "wouter";

import { AssignmentConfigurator } from "../../../components/AssignmentConfigurator/AssignmentConfigurator";
import { trpc } from "../../../shared/api/trpc";
import { AssignmentType } from "../../../shared/static/AssignmentType";
import { createRouteLink, routes } from "../../routes";

const FormEditor = lazy(() => import("../../../components/FormEditor"));

export const EditTemplatePage: FC<{ id: string }> = ({ id }) => {
  const [_, setLocation] = useLocation();
  const template = trpc.template.get.useQuery({ id });
  const updateTemplate = trpc.template.update.useMutation();
  const utils = trpc.useContext();
  const [config, setConfig] = useState<object>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<{ data: string }>();

  useEffect(() => {
    if (template.data) {
      setValue("data", JSON.stringify(template.data.data));
      setConfig(template.data.data as object);
    }
  }, [template.data]);

  return (
    <Container sx={{ marginY: 2 }} maxWidth={"xl"}>
      {updateTemplate.isLoading && (
        <LinearProgress
          sx={{ position: "absolute", left: 0, right: 0, zIndex: 10 }}
        />
      )}

      <Stack flexDirection="row" alignItems="center" gap={2}>
        <Link href={createRouteLink(routes.templates, {})}>
          <IconButton>
            <CaretLeft />
          </IconButton>
        </Link>
        <Typography variant="h4" fontWeight={"bold"} mt={3} mb={3} flex={1}>
          {template.data?.name}
        </Typography>
      </Stack>

      <form
        onSubmit={handleSubmit(async (d) => {
          if (template.isLoading) return;
          await updateTemplate.mutateAsync({
            id,
            data: JSON.parse(d.data),
          });

          utils.template.invalidate();

          setLocation(createRouteLink(routes.templates, {}));
        })}
      >
        <Stack alignItems={"flex-start"} gap={3} pb={3}>
          <Suspense>
            {template.data?.type === AssignmentType.SURVEY && (
              <FormEditor
                data={config}
                errorText={errors.data?.message}
                onRefresh={() => {
                  const form = getValues("data");
                  setConfig(form ? JSON.parse(form) : {});
                }}
                {...register("data")}
              />
            )}
          </Suspense>

          {template.data?.type === AssignmentType.ANNOTATION && (
            <AssignmentConfigurator
              value={config}
              onChange={(v) => {
                setValue("data", JSON.stringify(v));
              }}
            />
          )}

          <Button
            disabled={updateTemplate.isLoading}
            size="large"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </Stack>
      </form>
    </Container>
  );
};
