import {
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { BoundingBox, HelpCircle, Search } from "@promaton/icons";
import { FC, memo, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Virtuoso } from "react-virtuoso";
import { z } from "zod";

import { trpc } from "../../../shared/api/trpc";

const s3ResultSchema = z.object({
  slugs: z.array(z.string()),
  resourceLocation: z.string(),
  comparisonLocation: z.string().optional(),
});
export type S3QueryResult = z.infer<typeof s3ResultSchema>;
export const S3Search: FC<{
  onChange: (value: S3QueryResult) => void;
}> = memo(({ onChange }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<{ resourceLocation: string; comparisonLocation?: string }>({});

  const [resourceLocation, setResourceLocation] = useState<string>("");
  const [slugs, setSlugs] = useState<string[]>([]);
  const comparisonLocation = watch("comparisonLocation");

  const folderQuery = trpc.scan.queryS3.useQuery(
    { resourceLocation },
    { enabled: !!resourceLocation }
  );

  const data = useMemo(() => {
    if (!folderQuery.data) return;

    return folderQuery.data
      .map(({ Prefix }) => {
        const slug = Prefix?.split("/")
          .filter((s) => s)
          .at(-1);
        return slug;
      })
      .filter(Boolean) as string[];
  }, [folderQuery.data]);

  useEffect(() => {
    if (data?.length) {
      setSlugs(data);
    }
  }, [data]);

  useEffect(() => {
    const resourceLocationTrimmed = resourceLocation.replace(/\/$/, "");
    const comparisonLocationTrimmed = comparisonLocation?.replace(/\/$/, "");

    resourceLocation &&
      slugs.length &&
      onChange({
        slugs,
        resourceLocation: resourceLocationTrimmed,
        comparisonLocation: isValid ? comparisonLocationTrimmed : undefined,
      });
  }, [slugs, resourceLocation, onChange, isValid, comparisonLocation]);

  return (
    <Card
      sx={{
        width: "100%",
        marginBottom: 4,
      }}
    >
      <CardContent>
        <form
          onSubmit={handleSubmit((d) => {
            setResourceLocation(d.resourceLocation.replace(/\/$/, ""));
          })}
        >
          <Stack flexDirection="row" gap={2} alignItems={"flex-start"}>
            <Stack flex={1} gap={1}>
              <TextField
                {...register("resourceLocation", {
                  pattern: /s3:\/\/.+[a-zA-Z0-9/]$/,
                  minLength: 5,
                })}
                label="Resource Location"
                placeholder="s3://"
                error={!!errors.resourceLocation}
                fullWidth
              />
              <TextField
                {...register("comparisonLocation", {
                  pattern: /s3:\/\/.+[a-zA-Z0-9/]$/,
                })}
                label="Comparison location (optional)"
                placeholder="s3://"
                error={!!errors.comparisonLocation}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="s3:// location that is used as baseline comparison to the folders in the resource location">
                        <IconButton>
                          <HelpCircle sx={{ opacity: 0.5 }} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Button
              disabled={!!(folderQuery.isLoading && resourceLocation)}
              type="submit"
              size="large"
              variant="contained"
              startIcon={<Search />}
              sx={{
                minWidth: 130,
              }}
            >
              Search
            </Button>
          </Stack>
        </form>
      </CardContent>
      <Divider />
      <List
        sx={{
          padding: 0,
        }}
      >
        <Virtuoso
          style={{
            width: "100%",
            height: 600,
            maxHeight: "30vh",
          }}
          data={data}
          itemContent={(i, slug) => {
            const included = slugs.includes(slug);
            return slug ? (
              <ListItemButton
                sx={{ gap: 3 }}
                divider
                onClick={() => {
                  const newSlugs = included
                    ? slugs.filter((s) => s !== slug)
                    : [...slugs, slug];
                  setSlugs(newSlugs);
                }}
              >
                <Avatar
                  sx={{
                    background: (t) => t.palette.divider,
                  }}
                >
                  <BoundingBox color="info" />
                </Avatar>
                <ListItemText
                  sx={{
                    flex: 1,
                  }}
                  primary={slug}
                />
                <ListItemSecondaryAction>
                  <Checkbox checked={included} />
                </ListItemSecondaryAction>
              </ListItemButton>
            ) : null;
          }}
        />
      </List>
      <Divider />
      <CardContent>
        {folderQuery.isLoading && resourceLocation ? (
          <Typography>
            <CircularProgress
              size={16}
              sx={{
                marginRight: 2,
              }}
            />
            Loading...
          </Typography>
        ) : (
          <Typography>{slugs.length} scans selected</Typography>
        )}
      </CardContent>
    </Card>
  );
});
