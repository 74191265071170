import { GlobalStyles, useTheme } from "@mui/material";
import { GlobalDialogs } from "@promaton/frontend-common";
import { useObjects } from "@promaton/scan-viewer";
import { lazy, Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, useLocation, useRoute } from "wouter";

import { AdminPage } from "./routes/admin/AdminPage";
import { LoginPage } from "./routes/login/LoginPage";
import { Reviewpage } from "./routes/review/ReviewPage";
import { RootPage } from "./routes/root/RootPage";
import { routes } from "./routes/routes";
import DevOverlayRibbon from "./shared/ui/DevOverlayRibbon";
import { Environment, getEnvironment } from "./shared/utils/getEnv";
import { useAnnotations } from "./stores/useAnnotations";
import { useAppState } from "./stores/useAppState";

const AssignmentPage = lazy(() => import("./routes/assignment/AssignmentPage"));

function App() {
  const session = useAppState((s) => s.session);
  const [location, setLocation] = useLocation();
  const [isLogin] = useRoute(routes.login);
  const theme = useTheme();

  /** Auth related redirects */
  useEffect(() => {
    if (!session && !isLogin) {
      const params = new URLSearchParams({
        redirect: location,
      });
      setLocation(`${routes.login}?${params.toString()}`, {
        replace: true,
      });
    } else if (isLogin && session) {
      const params = new URLSearchParams(window.location.search);
      const redirect = params.get("state");
      setLocation(redirect || routes.root, { replace: true });
    }
  }, [session, location, isLogin]);

  /** Host related redirect */
  useEffect(() => {
    if (window.location.host === "annotator.dev.promaton.ai") {
      window.location.replace(
        `https://annotator.promaton.com${window.location.pathname}${window.location.search}`
      );
    }
  }, []);

  const [isAssignment] = useRoute(routes.assignment);
  const clearSubmissionState = useAnnotations((s) => s.clearSubmissionState);
  useEffect(() => {
    if (isAssignment) return;
    clearSubmissionState();
    useObjects.getState().setObjects({});
  }, [isAssignment]);

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={theme.palette.background.paper} />
      </Helmet>
      <GlobalStyles
        styles={{
          body: {
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            colorScheme: theme.palette.mode,
          },
        }}
      />
      {getEnvironment() === Environment.DEV && <DevOverlayRibbon />}
      <Switch>
        <Route path={routes.assignment}>
          {({ id, slug }) => (
            <Suspense>
              <AssignmentPage submissionId={id} slug={slug} />
            </Suspense>
          )}
        </Route>
        <Route path={routes.login}>{() => <LoginPage />}</Route>
        <Route path={routes.admin}>{() => <AdminPage />}</Route>
        <Route path={routes.review}>{() => <Reviewpage />}</Route>
        <Route>{() => <RootPage />}</Route>
      </Switch>
      <GlobalDialogs />
    </>
  );
}

export default App;
