import {
  Avatar,
  Button,
  Container,
  InputBase,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Unstable_Grid2 as Grid2,
} from "@mui/material";
import { CaretRight, Grid, Search } from "@promaton/icons";
import { FC, forwardRef, memo, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { TableVirtuoso } from "react-virtuoso";
import { Link } from "wouter";

import { Profile } from "../../components/Profile";
import { useQueryAndSessionState } from "../../hooks/useQueryAndSessionState";
import { Role, useRole } from "../../hooks/useRole";
import { trpc } from "../../shared/api/trpc";
import { EmptyState } from "../../shared/ui/EmptyState";
import { createRouteLink, routes } from "../routes";
import { AssignmentCard, AssignmentTableRow } from "./AssignmentCard";

export const RootPage: FC<{}> = memo(() => {
  const [project, setProject] = useQueryAndSessionState(
    "annotation-project",
    ""
  );

  const { register, watch } = useForm<{
    search: string;
  }>();

  const role = useRole();
  const search = watch("search");

  const canAnnotate = role !== Role.REVIEWER;
  const canReview = role !== Role.ANNOTATOR;

  const projects = trpc.project.getAll.useQuery({
    archived: false,
    availableOnly: true,
  });

  const inProgress = trpc.submission.listOwn.useQuery(
    {
      states: ["IN_PROGRESS", "REJECTED"],
      search,
      project: project || undefined,
    },
    { enabled: canAnnotate }
  );
  const readyForReview = trpc.submission.listAllReviewsForUser.useQuery(
    {
      pageSize: 12,
      project: project || undefined,
      availableOnly: true,
      draftOnly: true,
    },
    { enabled: canReview }
  );

  const assignments = trpc.assignment.list.useInfiniteQuery(
    {
      search: search?.toLowerCase(),
      available: true,
      project: project || undefined,
      excludeInProgress: true,
      sort: "name",
    },
    {
      getNextPageParam: (lastPage) => lastPage.at(-1)?.id,
    }
  );

  const availableAssignments = useMemo(() => {
    return assignments.data?.pages.flat();
  }, [assignments.data]);

  return (
    <Stack>
      <Helmet>
        <title>Promaton Annotator</title>
      </Helmet>
      <Paper
        variant="outlined"
        sx={{
          border: "none",
          position: "sticky",
          top: 0,
          zIndex: 1,
          background: (t) => t.palette.background.default,
        }}
      >
        <Container>
          <Stack
            flexDirection={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ marginY: 2, gap: 2 }}
          >
            <Avatar
              src="/promaton-logo.png"
              sx={{
                filter: (t) =>
                  t.palette.mode === "light"
                    ? "brightness(0.1)"
                    : "brightness(10)",
                transition: (t) =>
                  t.transitions.create("all", { duration: "0.5s" }),
                ["&:hover"]: {
                  transform: "rotate(360deg)",
                },
              }}
              imgProps={{
                draggable: false,
              }}
            />
            <Stack
              flexDirection="row"
              sx={{ flex: 1 }}
              justifyContent="center"
              gap={1}
            >
              <Select
                placeholder="Project"
                variant="outlined"
                displayEmpty
                renderValue={project !== "" ? undefined : () => "All projects"}
                sx={{
                  paddingLeft: 1,
                  border: "none",
                  width: 200,
                  background: (t) => t.palette.action.hover,
                  borderRadius: (t) => t.spacing(1),
                  ["& fieldset"]: {
                    borderColor: (t) =>
                      project ? `${t.palette.primary.main}` : undefined,
                  },
                }}
                onChange={(e) => setProject(e.target.value as string)}
                value={project}
              >
                <MenuItem value={""}>All projects</MenuItem>
                {projects.data?.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
              <InputBase
                startAdornment={<Search sx={{ marginRight: 1 }} />}
                type="search"
                placeholder="Search by slug"
                autoComplete="off"
                {...register("search", {
                  minLength: 1,
                })}
                sx={{
                  maxWidth: 300,
                  flex: 1,
                  padding: 1,
                  paddingLeft: 2,
                  background: (t) => t.palette.action.hover,
                  borderRadius: (t) => t.spacing(1),
                }}
              />
            </Stack>
            <Profile />
          </Stack>
        </Container>
      </Paper>

      {(assignments.isLoading ||
        (canAnnotate && inProgress.isLoading) ||
        (canReview && readyForReview.isLoading)) && (
        <LinearProgress
          sx={{ position: "absolute", left: 0, right: 0, zIndex: 10 }}
        />
      )}

      <Container>
        <Stack flexDirection="row" alignItems="center" gap={2} mt={4} mb={3}>
          <Typography variant="h4" fontWeight={"bold"} flex={1}>
            Assignments
          </Typography>
          {role === "admin" && (
            <Link href={createRouteLink(routes.adminRoot, {})}>
              <Button variant="outlined">Manage projects</Button>
            </Link>
          )}
        </Stack>

        {inProgress.data?.length ? (
          <>
            <Typography variant="h6" fontWeight={"bold"} flex={1} mb={2}>
              In progress
            </Typography>
            <Grid2 container spacing={2} mb={2}>
              {inProgress.data.map((i) => (
                <Grid2 key={i.id} xs={12} md={6} lg={4}>
                  <AssignmentCard submission={i} />
                </Grid2>
              ))}
            </Grid2>
          </>
        ) : null}

        {canReview && (
          <>
            <Stack flexDirection="row" alignItems="center" mb={2} gap={2}>
              <Typography variant="h6" fontWeight={"bold"}>
                Ready for review
              </Typography>
              <Link href={routes.review}>
                <Button endIcon={<CaretRight />}>See all</Button>
              </Link>
            </Stack>
            {readyForReview.data?.length ? (
              <Grid2 container spacing={2} mb={2}>
                {readyForReview.data.map((i) => (
                  <Grid2 key={i.id} xs={12} md={6} lg={4}>
                    <AssignmentCard submission={i} reviewMode />
                  </Grid2>
                ))}
              </Grid2>
            ) : null}
          </>
        )}

        {availableAssignments ? (
          <>
            <Typography variant="h6" mt={4}>
              Available assignments
            </Typography>

            <TableVirtuoso
              useWindowScroll
              endReached={() => {
                assignments.fetchNextPage();
              }}
              components={{
                Scroller: forwardRef((props, ref) => (
                  <div {...props} ref={ref}></div>
                )),
                Table: (props) => (
                  <Table
                    size="small"
                    {...props}
                    style={{ borderCollapse: "separate" }}
                  />
                ),
                TableHead: forwardRef((props, ref) => (
                  <TableHead
                    ref={ref}
                    {...props}
                    sx={{ top: "80px !important" }}
                  />
                )),
                TableRow: (props) => (
                  <TableRow
                    {...props}
                    sx={{
                      ["& td"]: {
                        borderBottom: "none",
                      },
                      ["&:not(:hover) button"]: {
                        opacity: 0,
                      },
                    }}
                  />
                ),
                TableBody: forwardRef((props, ref) => (
                  <TableBody {...props} ref={ref} />
                )),
              }}
              style={{ flex: 1, minHeight: 100 }}
              totalCount={availableAssignments?.length}
              data={availableAssignments}
              fixedHeaderContent={() => (
                <TableRow
                  sx={{
                    background: (t) => t.palette.background.default,

                    ["& th"]: {
                      borderBottomColor: (t) => t.palette.divider,
                    },
                  }}
                >
                  <TableCell sx={{ paddingLeft: 0 }}>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Project</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="right" sx={{ width: 0 }}></TableCell>
                </TableRow>
              )}
              itemContent={(index, assignment) =>
                assignment ? (
                  <AssignmentTableRow
                    assignment={assignment}
                    key={assignment.id}
                  />
                ) : null
              }
            />
          </>
        ) : null}
        {availableAssignments && availableAssignments.length === 0 && (
          <EmptyState>
            <Grid fontSize="large"></Grid>
            <Typography>
              No available assignments{project ? " in this project" : ""}.
            </Typography>
          </EmptyState>
        )}
      </Container>
    </Stack>
  );
});
