import { Brush, Label } from "@promaton/icons";
import type { ReactElement } from "react";
export const AssignmentType = {
  SURVEY: "SURVEY",
  ANNOTATION: "ANNOTATION",
} as const;

export type AssignmentType =
  (typeof AssignmentType)[keyof typeof AssignmentType];

export const AssignmentIcon: { [key in AssignmentType]: ReactElement } = {
  [AssignmentType.ANNOTATION]: <Brush />,
  [AssignmentType.SURVEY]: <Label />,
};
export const AssignmentTypeName: { [key in AssignmentType]: string } = {
  [AssignmentType.ANNOTATION]: "Annotation",
  [AssignmentType.SURVEY]: "Survey",
};
