import {
  Avatar,
  Container,
  Divider,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { Link, Redirect, Route, Switch, useLocation } from "wouter";

import { Profile } from "../../components/Profile";
import { useRole } from "../../hooks/useRole";
import { routes } from "../routes";
import { CreateProjectPage } from "./create-project/CreateProjectPage";
import { EditGroupPage } from "./edit-group/EditGroupPage";
import { EditProjectPage } from "./edit-project/EditProjectPage";
import { EditTemplatePage } from "./edit-template/EditTemplatePage";
import { ProjectDetailPage } from "./project-detail/ProjectDetailPage";
import { ProjectsPage } from "./projects/ProjectsPage";
import { TemplatesPage } from "./templates/TemplatesPage";
import { UsersPage } from "./users/UsersPage";

export const AdminPage = () => {
  const role = useRole();
  const isAdmin = role === "admin";
  const [location, setLocation] = useLocation();
  const root = location.split("/").slice(0, 3).join("/");

  return (
    <Stack sx={{ height: "100%" }}>
      <Helmet>
        <title>Promaton Annotator</title>
      </Helmet>
      <Paper
        variant="outlined"
        sx={{
          paddingY: 0.5,
          border: "none",
          position: "sticky",
          top: 0,
          zIndex: (t) => t.zIndex.fab,
          background: (t) => t.palette.background.default,
        }}
      >
        <Container maxWidth={"xl"}>
          <Stack
            flexDirection={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ marginY: 2, gap: 2 }}
          >
            <Link href={routes.root}>
              <Avatar
                src="/promaton-logo.png"
                sx={{
                  filter: (t) =>
                    t.palette.mode === "light"
                      ? "brightness(0.1)"
                      : "brightness(10)",
                  transition: (t) =>
                    t.transitions.create("all", { duration: "0.5s" }),
                  ["&:hover"]: {
                    transform: "rotate(360deg)",
                  },
                }}
                imgProps={{
                  draggable: false,
                }}
              />
            </Link>
            <Typography sx={{ flex: 1 }} variant="h6">
              Admin
            </Typography>

            <Profile />
          </Stack>
        </Container>
        <Container maxWidth={"xl"}>
          <Tabs
            value={root}
            onChange={(e, value) => {
              setLocation(value);
            }}
            sx={{
              ["& .MuiTab-root"]: {
                paddingLeft: 0,
                paddingRight: 0,
                marginRight: 3,
                minWidth: "auto",
                fontSize: (t) => t.typography.body1.fontSize,
                alignItems: "flex-start",
              },
            }}
          >
            <Tab label="Projects" value={routes.projects} />
            <Tab label="Users" value={routes.users} />
            <Tab label="Templates" value={routes.templates} />
          </Tabs>
        </Container>
        <Divider />
      </Paper>

      {!isAdmin && <Redirect href="/" />}

      <Switch>
        <Route path={routes.newProject}>{() => <CreateProjectPage />}</Route>
        <Route path={routes.projectEdit}>
          {({ id }) => <EditProjectPage id={id} />}
        </Route>
        <Route path={routes.projectDetail}>
          {({ id }) => <ProjectDetailPage id={id} />}
        </Route>
        <Route path={routes.projects}>{() => <ProjectsPage />}</Route>
        <Route path={routes.users}>{() => <UsersPage />}</Route>
        <Route path={routes.group}>
          {({ id }) => <EditGroupPage id={id} />}
        </Route>
        <Route path={routes.template}>
          {({ id }) => <EditTemplatePage id={id} />}
        </Route>
        <Route path={routes.templates}>{() => <TemplatesPage />}</Route>
        <Redirect href={routes.projects} replace />
      </Switch>
    </Stack>
  );
};
