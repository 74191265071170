import { zodResolver } from "@hookform/resolvers/zod";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { BoundingBox, Search } from "@promaton/icons";
import { FC, memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Virtuoso } from "react-virtuoso";
import { z } from "zod";

import { APIOutput } from "../../../shared/api/trpc";
import { formatDate } from "../../../shared/utils/dateFormatter";
import { getSlugsInText, slugRegex } from "../../../shared/utils/slugs";

const scanTypeOptions = ["IOS", "CBCT"] as const;

const filterSchema = z.object({
  slugs: z.array(z.string().regex(slugRegex)).optional(),
  slug: z.string().optional(),
  source: z.string().optional(),
  scanType: z
    .enum(scanTypeOptions)
    .or(z.string().transform(() => undefined))
    .optional(),
  limit: z.number().optional(),
});
export type FilterSchema = z.infer<typeof filterSchema>;

export type LakehouseResult = APIOutput["scan"]["queryLakehouse"];

type LakehouseSource = LakehouseResult["cases"][0]["data_source"]["provider"];

const LakehouseSources: Record<LakehouseSource, string> = {
  scanandshape: "Scan and Shape",
  apiupload: "API upload",
  clearcorrect: "ClearCorrect",
  dentalwings: "Dental Wings",
  designservices: "Design services",
  cdx: "cDX",
  altman: "Altman",
};

const LakehouseSearchTabs = {
  QUERY: "Query Data Lake House",
  LIST_OF_SLUGS: "Provide list of cases",
};

export const LakehouseSearch: FC<{
  filter?: FilterSchema;
  onChange: (value: FilterSchema) => void;
  loading?: boolean;
  data?: LakehouseResult;
}> = memo(({ filter, onChange, data, loading }) => {
  const [tab, setTab] = useState(LakehouseSearchTabs.QUERY);
  const [slugListText, setSlugListText] = useState<string>("");

  const {
    register: registerFilter,
    handleSubmit: handleSubmitFilter,
    setValue,
    getValues,
    watch,
    formState: { errors: filterErrors },
  } = useForm<FilterSchema>({
    resolver: zodResolver(filterSchema),
    defaultValues: {
      ...filter,
    },
  });

  const slugs = watch("slugs");

  useEffect(() => {
    if (tab === LakehouseSearchTabs.LIST_OF_SLUGS) {
      onChange({
        slugs: getValues("slugs"),
      });
    } else {
      onChange({
        slug: getValues("slug"),
        source: getValues("source"),
        scanType: getValues("scanType"),
        limit: getValues("limit"),
      });
    }
  }, [tab]);

  return (
    <Card
      sx={{
        width: "100%",
        marginBottom: 4,
      }}
    >
      <Tabs
        sx={{
          px: 2,
          pt: 1,
          ["& .MuiTab-root"]: {
            paddingLeft: 0,
            paddingRight: 0,
            marginRight: 3,
            minWidth: "auto",
            fontSize: (t) => t.typography.body1.fontSize,
            alignItems: "flex-start",
          },
        }}
        textColor="inherit"
        value={tab}
        onChange={(_, v) => setTab(v)}
      >
        <Tab
          value={LakehouseSearchTabs.QUERY}
          label={LakehouseSearchTabs.QUERY}
        />
        <Tab
          value={LakehouseSearchTabs.LIST_OF_SLUGS}
          label={LakehouseSearchTabs.LIST_OF_SLUGS}
        />
      </Tabs>
      {tab === LakehouseSearchTabs.QUERY && (
        <>
          <CardContent>
            <form
              onSubmit={handleSubmitFilter((d) => {
                console.log(d);
                onChange({
                  slug: d.slug,
                  source: d.source,
                  scanType: d.scanType,
                  limit: d.limit,
                });
              })}
            >
              <Stack flexDirection="row" gap={2}>
                <TextField {...registerFilter("slug")} label="Slug" fullWidth />
                <FormControl fullWidth error={!!filterErrors.source}>
                  <InputLabel>Source</InputLabel>
                  <Select
                    {...registerFilter("source", {
                      setValueAs: (v) => v || undefined,
                    })}
                    label={"Source"}
                  >
                    <MenuItem value={""}>Any</MenuItem>
                    {Object.entries(LakehouseSources).map(([option, label]) => (
                      <MenuItem key={option} value={option}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth error={!!filterErrors.scanType}>
                  <InputLabel>Scan type</InputLabel>
                  <Select
                    {...registerFilter("scanType", {
                      setValueAs: (v) => v || undefined,
                    })}
                    label={"Scan Type"}
                  >
                    <MenuItem value={""}>Any</MenuItem>
                    {scanTypeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option.split("_").join(" ")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  sx={{ maxWidth: 150 }}
                  {...registerFilter("limit", {
                    setValueAs(value: string) {
                      return value === "" ? undefined : parseInt(value);
                    },
                  })}
                  label="Limit"
                  fullWidth
                  type="number"
                />
                <Button
                  disabled={loading}
                  type="submit"
                  size="large"
                  variant="contained"
                  startIcon={<Search />}
                  sx={{
                    minWidth: 130,
                  }}
                >
                  APPLY
                </Button>
              </Stack>
            </form>
          </CardContent>
          <Divider />
          <List
            sx={{
              padding: 0,
            }}
          >
            <Virtuoso
              style={{
                width: "100%",
                height: 600,
                maxHeight: "30vh",
              }}
              data={data?.cases}
              itemContent={(i, data) => {
                return data ? (
                  <ListItem
                    sx={{
                      gap: 3,
                    }}
                  >
                    <Avatar
                      sx={{
                        background: (t) => t.palette.divider,
                      }}
                    >
                      <BoundingBox color="info" />
                    </Avatar>
                    <ListItemText
                      sx={{
                        flex: 1,
                      }}
                      primary={data.slug}
                      secondary={data.data_source.provider}
                    />
                    <Typography variant="body2">
                      {formatDate(data.created_at)}
                    </Typography>
                  </ListItem>
                ) : null;
              }}
            />
          </List>
        </>
      )}
      {tab === LakehouseSearchTabs.LIST_OF_SLUGS && (
        <CardContent>
          <TextField
            value={slugListText}
            onChange={(e) => {
              setSlugListText(e.target.value);
              const slugs = getSlugsInText(e.target.value);
              setValue("slugs", slugs);
              onChange({
                slugs,
              });
            }}
            label={"Case slugs"}
            placeholder={`Enter case slugs separated by a space, new line or ",".`}
            fullWidth
            multiline
            rows={10}
          />
        </CardContent>
      )}
      <Divider />
      <CardContent>
        {loading ? (
          <Typography>
            <CircularProgress
              size={16}
              sx={{
                marginRight: 2,
              }}
            />
            Loading...
          </Typography>
        ) : (
          <Typography>
            {tab === LakehouseSearchTabs.LIST_OF_SLUGS
              ? (slugs?.length ?? 0) + " cases selected"
              : (data?.totalCount ?? 0) + " cases selected"}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
});
