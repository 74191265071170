import { FileType } from "@promaton/scan-viewer";
import produce from "immer";
import { BufferGeometry } from "three";
import { z } from "zod";
import { create } from "zustand";

import { AssignmentStep } from "../shared/static/AssignmentConfiguration";
import {
  getFileTypeForStep,
  getGeometryName,
} from "../shared/utils/getGeometryName";

const PointSchema = z.object({
  position: z.tuple([z.number(), z.number(), z.number()]),
  radius: z.number().optional(),
});

export type PointData = z.infer<typeof PointSchema>;

export const AnnotationSchema = z.record(
  z.record(
    z.object({
      points: z.array(PointSchema).optional(),
      color: z.string().optional(),
      closed: z.boolean().optional(),
      geometry: z.string().optional(),
      target: z.string().optional(),
    })
  )
);

export type AnnotationMap = z.infer<typeof AnnotationSchema>;
export type AnnotationData = AnnotationMap[keyof AnnotationMap][number];

export const DEFAULT_SEGMENT_NAME = "points";

type Selection = { step: string; segment: string; index: number } | null;

type UploadTask = {
  id: string;
  type: FileType;
};

interface AnnotationState {
  /** Primary group / step in annotation task */
  activeStep: AssignmentStep | null;

  getStep: (name: string) => AssignmentStep | null;

  /** Sub-region, e.g. fdi number of tooth */
  activeSegment: string | null;

  update: (state: Partial<AnnotationState>) => void;
  setActiveStepAndSegment: (step?: string, segment?: string) => void;

  annotations: AnnotationMap;

  selection: Selection;
  setSelection: (selection: Selection) => void;

  hiddenSteps: string[];
  setStepHidden: (step: string, hidden: boolean) => void;

  hiddenTargets: string[];
  setTargetHidden: (target: string, hidden: boolean) => void;

  displayPointOrder: boolean;
  setDisplayPointOrder: (display: boolean) => void;

  updateAnnotation: (
    step: string,
    segment: string,
    annotation: Partial<AnnotationData>
  ) => void;
  deleteAnnotation: (step: string, segment: string) => void;

  geometries: Record<string, BufferGeometry>;
  setGeometry: (
    step: string,
    segment: string,
    geometry: BufferGeometry,
    target: string
  ) => void;
  getGeometry: (step: string, segment: string) => BufferGeometry | undefined;

  uploadQueue: UploadTask[];
  addToUploadQueue: (step: AssignmentStep, segment: string) => void;
  emptyUploadQueue: () => void;

  clearSubmissionState: () => void;
}
export const useAnnotations = create<AnnotationState>((set, get) => ({
  activeStep: null,
  getStep: () => null,
  activeSegment: "",
  update: (state) => {
    set(state);
  },
  setActiveStepAndSegment: (step, segment) => {
    const stepData = step ? get().getStep(step) : null;
    set(
      produce<AnnotationState>((s) => {
        s.selection = null;
        s.activeStep = stepData;
        s.activeSegment = stepData ? segment ?? null : null;
        return s;
      })
    );
  },
  annotations: {},
  updateAnnotation: (step, segment, annotation) => {
    set(
      produce<AnnotationState>((s) => {
        s.annotations[step] = {
          ...s.annotations[step],
          [segment]: { ...s.annotations[step]?.[segment], ...annotation },
        };
        return s;
      })
    );
  },
  deleteAnnotation: (step, segment) => {
    set(
      produce<AnnotationState>((s) => {
        delete s.annotations[step]?.[segment];
        const geometryName = getGeometryName(step, segment);
        delete s.geometries[geometryName];
        s.uploadQueue = s.uploadQueue.filter((i) => i.id !== geometryName);
        return s;
      })
    );
  },
  selection: null,
  setSelection: (selection) => {
    set({ selection });
  },
  hiddenSteps: [],
  setStepHidden: (step, hidden) => {
    set(
      produce<AnnotationState>((s) => {
        if (hidden) {
          s.hiddenSteps.push(step);
        } else {
          s.hiddenSteps = s.hiddenSteps.filter((s) => s !== step);
        }
        return s;
      })
    );
  },
  hiddenTargets: [],
  setTargetHidden: (target, hidden) => {
    set(
      produce<AnnotationState>((s) => {
        if (hidden) {
          s.hiddenTargets.push(target);
        } else {
          s.hiddenTargets = s.hiddenTargets.filter((s) => s !== target);
        }
        return s;
      })
    );
  },
  displayPointOrder: true,
  setDisplayPointOrder: (displayPointOrder) => {
    set({ displayPointOrder });
  },
  geometries: {},
  setGeometry: (step, segment, geometry, target) => {
    set(
      produce<AnnotationState>((s) => {
        const name = getGeometryName(step, segment);
        s.geometries[name] = geometry;
        s.annotations[step] = {
          ...s.annotations[step],
          [segment]: {
            ...s.annotations[step]?.[segment],
            geometry: name,
            target,
          },
        };
        return s;
      })
    );
  },
  getGeometry: (step, segment) => {
    const name = getGeometryName(step, segment);
    return get().geometries[name];
  },

  uploadQueue: [],
  addToUploadQueue: (step, segment) => {
    const id = getGeometryName(step.name, segment);
    const task: UploadTask = {
      id,
      type: getFileTypeForStep(step),
    };
    set(
      produce<AnnotationState>((s) => {
        s.uploadQueue = s.uploadQueue.filter((i) => i.id !== task.id);
        s.uploadQueue.push(task);
        return s;
      })
    );
  },
  emptyUploadQueue: () => {
    set(
      produce<AnnotationState>((s) => {
        s.uploadQueue = [];
        return s;
      })
    );
  },

  clearSubmissionState: () => {
    set(
      produce<AnnotationState>((s) => {
        s.uploadQueue = [];
        s.annotations = {};
        s.geometries = {};
        s.hiddenSteps = [];
        s.hiddenTargets = [];
        s.activeSegment = null;
        s.activeStep = null;
        return s;
      })
    );
  },
}));
