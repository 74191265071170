import {
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  InputBase,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { Close, Search } from "@promaton/icons";
import {
  inferTypeFromUrl,
  isImageFile,
  useObjects,
} from "@promaton/scan-viewer";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import ga4 from "react-ga4";
import { useKey } from "react-use";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { useShallow } from "zustand/shallow";

import { useCommandPalette } from "./useCommandPalette";

export const CommandPalette = memo(() => {
  const [open, setOpen] = useCommandPalette(
    useShallow((s) => [s.open, s.setOpen])
  );

  const listRef = useRef<VirtuosoHandle>(null);
  const [query, setQuery] = useState("");
  const [index, setIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  useKey(
    "k",
    (e) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        setOpen(!open);
      }
    },
    {},
    [open]
  );

  const results = useMemo(() => {
    const state = useCommandPalette.getState();
    return query
      ? state.searchCommands(query).map((i) => i.item)
      : Object.values(state.commands);
  }, [open, query]);

  useEffect(() => {
    setIndex(0);
  }, [query]);

  useKey(
    "Enter",
    () => {
      if (open) {
        const item = results[index];
        if (item) {
          item.action();
          ga4.event({
            category: "commandPallete",
            action: "commandAction",
            label: item.name,
          });
          setOpen(false);
          setQuery("");
        }
      }
    },
    undefined,
    [open, results, index]
  );

  useKey(
    "ArrowUp",
    (e) => {
      if (open) {
        e.preventDefault();
        setIndex((i) => Math.max(0, i - 1));
      }
    },
    undefined,
    [open]
  );

  useKey(
    "ArrowDown",
    (e) => {
      if (open) {
        e.preventDefault();
        setIndex((i) => Math.min(results.length - 1, i + 1));
      }
    },
    undefined,
    [open, results]
  );

  useEffect(() => {
    if (open) {
      listRef.current?.scrollToIndex({
        index,
        align: "center",
        behavior: "smooth",
      });
    }
  }, [open, index]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 150);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        transitionDuration={150}
        onClose={() => setOpen(false)}
        slotProps={{ backdrop: { style: { background: "none" } } }}
        PaperProps={{
          variant: "outlined",
          sx: {
            borderRadius: 4,
            width: 800,
            maxWidth: 800,
            padding: 0,
            background: (t) => `${t.palette.background.paper}bb`,
            backdropFilter: "blur(15px)",
            boxShadow: "0 1rem 2rem -0.25rem rgba(0,0,0,0.25)",
            ["& *::-webkit-scrollbar"]: {
              display: "none",
            },
          },
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <InputBase
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            endAdornment={
              query ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setQuery("");
                      inputRef.current?.focus();
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : undefined
            }
            disabled={!open}
            autoFocus
            inputRef={inputRef}
            fullWidth
            sx={{ fontSize: "1.25rem", padding: 2 }}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="What do you want to do?"
          />
          <Virtuoso
            ref={listRef}
            style={{ height: 400, width: "100%" }}
            data={results}
            itemContent={(i, d) => {
              if (!d) return null;
              const selected = index === i;
              return (
                <ListItemButton
                  onClick={() => {
                    d.action();
                    ga4.event({
                      category: "commandPallete",
                      action: "commandAction",
                      label: d.name,
                    });
                    setOpen(false);
                    setQuery("");
                  }}
                  tabIndex={-1}
                  selected={selected}
                  sx={{
                    transition: "none",
                    mx: 1,
                    borderRadius: 2,
                    opacity: selected ? 1 : 0.8,
                    ["&.Mui-selected"]: {
                      background: (t) => `${t.palette.primary.main} !important`,
                      color: (t) => t.palette.primary.contrastText,
                    },
                  }}
                >
                  <ListItemText
                    primary={d.name}
                    secondary={d.description}
                    primaryTypographyProps={{
                      sx: { fontWeight: selected ? "bold" : "normal" },
                    }}
                    secondaryTypographyProps={{
                      sx: { color: (t) => t.typography.body1.color },
                    }}
                  />
                  {selected && (
                    <ListItemSecondaryAction>
                      <Typography
                        variant="body2"
                        sx={{
                          background: (t) => t.palette.action.selected,
                          border: (t) => `1px solid ${t.palette.divider}`,
                          px: 1,
                          py: 0.25,
                          borderRadius: 2,
                        }}
                      >
                        ↩
                      </Typography>
                    </ListItemSecondaryAction>
                  )}
                </ListItemButton>
              );
            }}
          />
        </DialogContent>
      </Dialog>
      <ExampleCommands />
    </>
  );
});

const ExampleCommands = memo(() => {
  const updateCommand = useCommandPalette((s) => s.updateCommand);
  const removeCommand = useCommandPalette((s) => s.removeCommand);

  useEffect(() => {
    updateCommand("volumeRendering", {
      name: "Toggle volume rendering",
      description: "Shows CBCT images as 3D volume in 3D view",
      action: () => {
        const objectState = useObjects.getState();

        Object.entries(objectState.objects).forEach(([key, object]) => {
          if (
            isImageFile(object?.objectType || inferTypeFromUrl(object!.url))
          ) {
            objectState.updateObject(key, {
              renderVolume: !object?.renderVolume,
              excludeInOrientations: [],
            });
          }
        });
      },
    });

    return () => {
      removeCommand("volumeRendering");
      removeCommand("toggleSelection");
    };
  });

  return null;
});
